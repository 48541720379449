exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-docs-en-jsx": () => import("./../../../src/pages/docs.en.jsx" /* webpackChunkName: "component---src-pages-docs-en-jsx" */),
  "component---src-pages-features-en-jsx": () => import("./../../../src/pages/features.en.jsx" /* webpackChunkName: "component---src-pages-features-en-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-notice-en-jsx": () => import("./../../../src/pages/legal-notice.en.jsx" /* webpackChunkName: "component---src-pages-legal-notice-en-jsx" */),
  "component---src-pages-open-id-connect-gateway-en-jsx": () => import("./../../../src/pages/open-id-connect-gateway.en.jsx" /* webpackChunkName: "component---src-pages-open-id-connect-gateway-en-jsx" */),
  "component---src-pages-use-cases-en-jsx": () => import("./../../../src/pages/use-cases.en.jsx" /* webpackChunkName: "component---src-pages-use-cases-en-jsx" */)
}

