/* eslint-disable max-len */
import * as React from 'react'

export default () => (
  <svg
    width={152}
    height={165}
    viewBox='0 0 152 165'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>couper.io logo</title>
    <g fill='none' fillRule='evenodd'>
      <path
        d='M87.395 42.775c-3.714-.7-7.537-1.106-11.457-1.106-33.907 0-61.394 27.439-61.394 61.286v11.33l22.913 6.436v-17.766c0-29.935 21.511-54.818 49.938-60.18'
        fill='#E3E4E4'
      />
      <path
        fill='#65B32E'
        d='M97.262.364L88.698 15.17 80.22.364l-8.57 14.821L63.114.38l-8.57 14.812-2.536-4.38H39.85v6.03h8.67l6.024 10.407L63.1 12.451l8.54 14.806 8.551-14.79 8.48 14.802 8.563-14.803 2.533 4.422h10.107V10.86h-6.604z'
      />
      <path
        d='M17.565 114.748v-11.795c0-32.129 26.186-58.269 58.372-58.269 32.189 0 58.375 26.14 58.375 58.27v11.755c-6.208 1.724-26.413 6.514-59.115 6.514-32.536 0-51.71-4.742-57.632-6.475m12.942-101.15a4.411 4.411 0 014.412-4.404 4.411 4.411 0 014.411 4.404 4.411 4.411 0 01-4.411 4.404 4.411 4.411 0 01-4.412-4.404m81.77 0a4.414 4.414 0 018.827 0 4.414 4.414 0 01-8.826 0m7.621 42.492V23.476c4.189-1.363 7.244-5.251 7.244-9.878 0-5.751-4.69-10.433-10.45-10.433-5.765 0-10.454 4.682-10.454 10.433 0 4.765 3.237 8.75 7.621 9.992v27.506c-10.653-7.786-23.737-12.44-37.923-12.44-14.183 0-27.267 4.654-37.92 12.44V23.509c4.245-1.326 7.352-5.245 7.352-9.911 0-5.751-4.69-10.433-10.45-10.433-5.762 0-10.451 4.682-10.451 10.433 0 4.726 3.185 8.681 7.51 9.96V56.09c-12.553 11.74-20.452 28.38-20.452 46.863v49.187h6.039v-31.113c5.628 1.54 17.175 4.105 34.846 5.408l-.021 25.702 6.04.006.02-25.332c5.146.272 10.69.44 16.747.44 6.58 0 12.6-.198 18.17-.512l-.02 25.398 6.04.006.021-25.802c17.532-1.353 29.236-3.888 34.904-5.377v31.176h6.04v-49.187c0-18.483-7.9-35.122-20.453-46.863'
        fill='#1A1B1C'
      />
      <path
        d='M82.703 87.31c0 7.478 6.072 13.54 13.564 13.54s13.561-6.062 13.561-13.54c0-7.476-6.07-13.54-13.561-13.54-7.492 0-13.564 6.064-13.564 13.54'
        fill='#3EA1C1'
      />
      <path
        d='M96.266 76.784c-5.813 0-10.544 4.72-10.544 10.526 0 5.803 4.731 10.526 10.544 10.526 5.813 0 10.545-4.723 10.545-10.526 0-5.805-4.732-10.526-10.545-10.526m0 27.08c-9.143 0-16.584-7.424-16.584-16.554 0-9.127 7.44-16.554 16.584-16.554 9.143 0 16.584 7.427 16.584 16.554 0 9.13-7.44 16.555-16.584 16.555'
        fill='#1A1B1C'
      />
      <path
        d='M100.992 87.31a4.72 4.72 0 00-4.726-4.715 4.718 4.718 0 00-4.723 4.714 4.72 4.72 0 004.723 4.718 4.723 4.723 0 004.726-4.718'
        fill='#1A1B1C'
      />
      <path
        d='M104.858 82.514a4.713 4.713 0 00-3.038-5.941 4.728 4.728 0 00-5.952 3.035 4.716 4.716 0 003.038 5.938 4.726 4.726 0 005.952-3.032'
        fill='#FFF'
      />
      <path
        d='M28.878 164.681h-6.04v-3.014c0-4.612-3.755-8.361-8.372-8.361-4.62 0-8.377 3.75-8.377 8.361v3.014H.05v-3.014c0-7.933 6.469-14.39 14.417-14.39 7.944 0 14.412 6.457 14.412 14.39v3.014z'
        fill='#AA64AA'
      />
      <path
        d='M151.749 164.681h-6.04v-3.014c0-4.612-3.756-8.361-8.373-8.361-4.62 0-8.376 3.75-8.376 8.361v3.014h-6.04v-3.014c0-7.933 6.465-14.39 14.416-14.39 7.948 0 14.413 6.457 14.413 14.39v3.014z'
        fill='#EB3D3E'
      />
      <path
        d='M69.836 164.681h-6.04v-3.014c0-4.612-3.756-8.361-8.376-8.361-4.617 0-8.373 3.75-8.373 8.361v3.014h-6.04v-3.014c0-7.933 6.466-14.39 14.413-14.39 7.948 0 14.416 6.457 14.416 14.39v3.014z'
        fill='#3EA1C1'
      />
      <path
        d='M110.792 164.681h-6.039v-3.014c0-4.612-3.756-8.361-8.376-8.361-4.617 0-8.374 3.75-8.374 8.361v3.014h-6.039v-3.014c0-7.933 6.465-14.39 14.413-14.39 7.947 0 14.415 6.457 14.415 14.39v3.014z'
        fill='#F6AB1F'
      />
    </g>
  </svg>
)
