module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-PH17X7VL05"],"gtagConfig":{"send_page_view":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"couper.io","short_name":"couper.io","start_url":"/","background_color":"#fff","theme_color":"#65B32E","display":"standalone","icon":"src/assets/images/icon.png","legacy":false,"include_favicon":false,"crossOrigin":"use-credentials","theme_color_in_head":true,"cache_busting_mode":"query","cacheDigest":"36db0acab5d2241ffea2ef5736fe28e0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/couper-website/couper-website/src/layout/Frame/index.jsx"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
